<template>
  <div
    class="relative w-full rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group flex flex-col gap-0.5 text-left"
  >
    <button class="flex w-full gap-2" @click="openProcedure">
      <div class="flex flex-col md:flex-row flex-1 md:items-center items-start md:gap-1">
        <div class="flex items-center gap-1 meeting-date text-sm md:text-base">
          <fw-icon-calendar-event class="w-5 h-5 flex-shrink-0 text-gray-500" />
          <span class="text-gray-500 flex-shrink-0">{{ item.date | formatDateTime }}</span>
        </div>
        <div>
          <div class="font-semibold truncate">{{ $t('meeting') }} {{ $t(`meetingsGoals.${item.goal}`) }}</div>
        </div>
      </div>
      <div class="flex gap-2 md:items-center">
        <div class="flex-shrink-0 text-sm md:text-base">
          <span class="text-gray-500 font-medium">{{ procedure.prefix }}</span>
          <span class="font-semibold">{{ procedure.code }}</span>
        </div>
        <div
          :class="[colors[procedure.status]]"
          class="hidden flex-shrink-0 md:inline-flex h-7 px-3 py-1 rounded-lg bg-opacity-20 text-xs text-opacity-90 font-medium items-center"
        >
          <span>{{ $t(`procedureStatus.${procedure.status}`) }}</span>
          <fw-icon-check v-if="procedure.status === 'published'" class="w-4 h-4" />
        </div>
      </div>
    </button>
    <div class="text-xs text-gray-500">
      <a v-if="item.location && isValidUrl(item.location)" target="_blank" :href="item.location">{{ item.location }}</a>
      <div v-else>{{ item.location }}</div>
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'RecordManageProcedure',
  props: {
    item: Object,
    language: {
      type: String,
      default: 'pt',
    },
  },
  data() {
    return {
      colors: {
        draft: 'bg-yellow-300',
        published: 'bg-teal-400',
        jury: 'bg-teal-400',
        running: 'bg-teal-300',
        ended: 'bg-gray-200',
        closed: 'bg-gray-200',
      },
    }
  },
  computed: {
    procedureTitle() {
      return this.$store.getters.getProcedureTitle(this.item.procedure)
    },
    procedure() {
      return this.item.procedure
    },
  },
  methods: {
    openProcedure() {
      this.$router.push(`/manage/procedure/${this.item.procedure.key}/dashboard`)
    },
    isValidUrl(str) {
      return utils.isValidURL(str)
    },
  },
}
</script>

<style scoped>
.meeting-date {
  width: 10.5rem;
}
</style>

<i18n>
{
  "pt": {
    "meeting": "Reunião",
    "procedureStatus": {
      "label": "Estados do procedimento",
      "draft": "Rascunho",
      "jury": "Júri",
      "running": "Iniciado",
      "ended": "Audiência(s) de interessados",
      "closed": "Encerrado",
      "published": "Publicado"
    },
    "meetingsGoals": {
      "jury": "Definição do júri",
      "criteria": "Definição de critérios",
      "admission": "Admissão de candidatos",
      "seriation-curriculum": "Avaliação curricular",
      "seriation-interview": "Entrevista a candidatos",
      "complaints": "Audiência(s) de interessados"
    }
  },
  "en": {
    "meeting": "Meeting",
    "procedureStatus": {
      "label": "Procedure status",
      "draft": "Draft",
      "jury": "Jury",
      "running": "Running",
      "ended": "Stakeholder Hearing",
      "closed": "Ended",
      "published": "Published"
    },
    "meetingsGoals": {
      "jury": "Jury Definition",
      "criteria": "Definition of criteria",
      "admission": "Admission of candidates",
      "seriation-curriculum": "Curriculum evaluation",
      "seriation-interview": "Candidate interviews",
      "complaints": "Stakeholder Hearing"
    }
  }
}
</i18n>
