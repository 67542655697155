<template>
  <b-dropdown v-if="userRoles && userRoles.length" aria-role="list" position="is-bottom-right">
    <fw-button-dropdown
      slot="trigger"
      type="link-black"
      :size="$device.isWDesktop() ? 'md' : 'sm'"
      class="flex flex-col"
      :label="seeAsPublic ? $t('personal') : $t('managment')"
      :upper-label="$t('area')"
    >
    </fw-button-dropdown>
    <b-dropdown-item
      class="flex gap-1 items-center font-medium"
      :label="$t('personal')"
      aria-role="listitem"
      :class="{ 'text-primary': seeAsPublic }"
      @click.native="goToPublic"
    >
      {{ $t('personal') }}
    </b-dropdown-item>
    <b-dropdown-item
      :label="$t('managment')"
      aria-role="listitem"
      class="flex gap-1 items-center font-medium"
      :class="{ 'text-primary': !seeAsPublic }"
      @click.native="goToManage"
    >
      {{ $t('managment') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getUser
    },

    userRoles() {
      return this.$store.getters.getUserPermissions
    },

    seeAsPublic() {
      return this.$store.getters.getSeeAsPublic
    },
  },

  methods: {
    goToPublic() {
      this.$store.dispatch('setSeeAsPublic', true)
      if (this.$route.name != 'home') {
        this.$router.push({ name: 'home' })
      }
    },

    goToManage() {
      this.$store.dispatch('setSeeAsPublic', false)
      if (this.$route.name != 'manage') {
        this.$router.push({ name: 'manage' })
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "personal": "Pessoal",
    "managment": "Gestão",
    "area": "Área"
  },
  "en": {
    "personal": "Personal",
    "managment": "Managment",
    "area": "Area"
  }
}
</i18n>
