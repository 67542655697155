<template>
  <div class="block-uc-id-qr-code flex flex-col justify-center items-center">
    <div class="flex">
      <div class="relative">
        <figure class="overflow-hidden rounded-2xl shadow-xl w-28 h-28">
          <img v-if="getPhotoUrl" :src="getPhotoUrl" />
          <span
            v-else-if="user"
            class="w-28 h-28 flex items-center justify-center font-semibold text-5xl text-white bg-gradient-to-r bg-gray-800 from-gray-600 to-gray-800"
            >{{ user.initials }}</span
          >
          <img v-else-if="getLocalUserPhoto" :src="getLocalUserPhoto" />
          <span
            v-else
            class="w-28 h-28 flex items-center justify-center font-semibold text-white bg-gray-800 bg-gradient-to-r from-gray-600 to-gray-800"
            ><fw-icon-user2 class="w-20 h-20"></fw-icon-user2
          ></span>
        </figure>
        <div
          v-if="qrcode"
          class="z-1 rounded-md shadow-xl p-0.5 flex items-center justify-center absolute -bottom-1.5 -right-1.5 bg-white"
        >
          <fw-icon-qrcode class="w-10 h-10 text-primary" />
        </div>
      </div>
    </div>
    <div v-if="intro" class="mt-5">
      <h2 class="text-xl font-semibold text-center">
        <v-clamp autoresize :max-lines="2">{{ $t('hello') }}, {{ user.first_name }}.</v-clamp>
      </h2>
    </div>
    <b-modal
      v-if="qrcode"
      :active.sync="isOpened"
      :width="640"
      scroll="keep"
      full-screen
      :can-cancel="false"
      class="h-full"
    >
      <div class="p-5 flex flex-col h-full justify-center">
        <fw-heading class="text-center" size="h1">UC Id</fw-heading>
        <div class="my-4 flex-1 justify-center items-center flex">
          <b-loading :active.sync="isLoading"></b-loading>
          <div v-if="!isLoading && qrCode">
            <div class="flex justify-center p-2 bg-white" @click="getQRCode">
              <qrcode-vue :value="qrCode" :size="250" level="H"></qrcode-vue>
            </div>
            <div class="text-center my-5">{{ qrCode }}</div>
            <div class="text-center text-xs text-gray-500">{{ $t('turnQrcode') }}</div>
          </div>
        </div>
        <fw-button type="black" :expanded="true" @click.native="isOpened = false">{{ $t('close') }}</fw-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import QrcodeVue from 'qrcode.vue'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    QrcodeVue,
  },

  props: {
    user: {
      type: Object,
    },
    openQRcode: {
      type: Boolean,
    },
    intro: {
      type: Boolean,
    },
    qrcode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      assetsPath: process.env.VUE_APP_ROUTER_BASE || '/',
      isLoading: true,
      isOpened: this.openQRcode || false,
      qrCode: false,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    getPhotoUrl() {
      return ServiceStorage.getUserImageViewUrl(this.user || this.loggedUser, 'medium')
    },
    getLocalUserPhoto() {
      return this.$store.getters.getLocalUser.photoUrl
    },
  },

  watch: {
    openQRcode(value) {
      this.isOpened = value
    },
  },

  methods: {
    closeModal() {
      this.isOpened = false
    },

    async getQRCode() {
      this.isLoading = true
      try {
        this.qrCode = await ServiceAuth.getQRCodeString()
        this.isOpened = true
      } finally {
        this.isLoading = false
      }
    },

    humanizedTimeOfDay() {
      const currentHour = Dates.now().format('HH')
      return currentHour < 12 ? 'Bom dia' : currentHour <= 20 ? 'Boa tarde' : 'Boa noite'
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "hello": "Olá",
    "close": "Fechar",
    "turnQrcode": "Vire o código para o leitor."
  },
  "en": {
    "hello": "Hello",
    "close": "Close",
    "turnQrcode": "Turn the qrcode over to the reader."
  }
}
</i18n>
