<template>
  <fw-layout footer header-language-switch mobile-ready :back-to-enable="false" :management="showHomeAsManage">
    <template #header-nav>
      <HeaderSwitchManage />
    </template>

    <template #main-content>
      <PanelUCIdHero
        :user="user"
        class="mt-4"
        version="v2"
        :stats="stats"
        :stats-title="isProcedureManagerOrManager || isAdmin ? null : ''"
        :stats-loading="loadingStats"
        :management="showHomeAsManage"
      />
      <p v-if="showHomeAsManage" class="text-xs text-gray-400 mt-3 mb-10 px-4">
        * Os números apresentados dizem respeito à totalidade dos procedimentos aos quais tenha sido relacionado. Na
        qualidade de membro de júri, o presidente acederá ao detalhe dos mesmos a partir da publicação e, na qualidade
        de vogal, para além de publicado, o procedimento terá de ser aberto ao júri para aceder ao detalhe do mesmo.
      </p>

      <!-- For Admin -->
      <div v-if="showHomeAsManage">
        <fw-panel :title="$t('quickAccess')">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 justify-center mb-10 items-stretch">
            <button-quick-access
              v-if="lastProcedure"
              :label="lastProcedure ? lastProcedure.prefix + lastProcedure.code : $t('procedure.label')"
              :title="$t('procedure.openLast')"
              type="yellow"
              @clicked="openPage('last', lastProcedure.key)"
            />
            <button-quick-access
              v-if="isProcedureManager"
              :label="$t('quickAction')"
              :title="$t('procedure.create')"
              type="yellow"
              @clicked="showModalNewProcedure = true"
            />
            <button-quick-access
              :label="$t('quickAccess')"
              :title="$t('procedure.seeAll')"
              type="yellow"
              @clicked="openPage('all')"
            />
            <button-quick-access
              v-if="isProcedureManagerOrManager || isAdmin"
              label="Diretório"
              title="Pessoas"
              type="yellow"
              @clicked="$router.push('/manage/people')"
            />
            <button-quick-access
              v-if="isAdmin"
              label="Auditoria"
              title="Atividade"
              type="yellow"
              @clicked="$router.push('/manage/activity')"
            />
            <button-quick-access
              v-if="isAdmin"
              label="Auditoria"
              title="Notificações"
              type="yellow"
              @clicked="$router.push('/manage/notifications')"
            />
          </div>
        </fw-panel>

        <WeeklyEventsPanel
          class="mt-5"
          :loading="loadingWeeksPanel"
          :weeks="weeks"
          @get-week-events="getUserWeekEvents"
        >
          <template #events="{events}">
            <div v-for="(evt, ee) in events" :key="'event_' + ee">
              <div
                class="eventbox bg-primary flex flex-col gap-0.5 cursor-pointer"
                :class="{ has_link: Boolean(evt.url) }"
                @click="$router.push(`/manage/procedure/${evt.procedure.key}/dashboard`)"
              >
                <div v-if="evt.date" class="flex-shrink-0 flex gap-0.5 items-center">
                  <fw-icon-clock-line class="text-white opacity-60 w-4 h-4" />
                  <span>{{ evt.date | formatTime }}</span>
                </div>
                <div class="min-w-0">
                  <div class="font-bold capitalize truncate">{{ $t(`meetingsGoals.${evt.goal}`) }}</div>
                  <div class="text-xs font-medium truncate">{{ evt.location }}</div>
                  <div class="text-xs font-medium truncate">{{ evt.procedure.code }}</div>
                </div>
              </div>
              <div v-if="!evt.date && events[ee + 1] && events[ee + 1].date" class="event-separator"></div>
            </div>
          </template>
        </WeeklyEventsPanel>

        <UpNextPanel class="mt-10" />
      </div>

      <!-- Personal -->
      <div v-else>
        <fw-panel :title="$t('myApplications')" class="my-5">
          <ContentLoader v-if="loading" />
          <div v-else>
            <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5">
              <card-application
                v-for="application in applications.slice().reverse()"
                :key="application.code"
                :application="application"
                @click.native="goToApplication(application)"
              />
            </div>
          </div>
        </fw-panel>
        <fw-panel title="Explorar" featured class="mt-10">
          <panel-public-explore-procedures />
        </fw-panel>
      </div>
    </template>

    <template #modals>
      <fw-modal
        :title="$t('createProcedure')"
        :description="$t('chooseprocedureType')"
        :active="showModalNewProcedure"
        @close="showModalNewProcedure = false"
      >
        <modal-manage-new-procedure @close="showModalNewProcedure = false" />
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import PanelPublicExploreProcedures from '@/components/panels/PanelPublicExploreProcedures'
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import ContentLoader from '@/components/utilities/ContentLoader'
import ModalManageNewProcedure from '@/components/modals/ModalManageNewProcedure'
import HeaderSwitchManage from '@/components/header/HeaderSwitchManage'
import UpNextPanel from '@/components/panels/UpNextPanel'
import WeeklyEventsPanel from '@/fw-modules/fw-core-vue/ui/components/panels/WeeklyEventsPanel'
import CardApplication from '@/components/cards/CardApplication'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    CardApplication,
    ContentLoader,
    HeaderSwitchManage,
    PanelUCIdHero,
    UpNextPanel,
    WeeklyEventsPanel,
    ButtonQuickAccess,
    PanelPublicExploreProcedures,
    ModalManageNewProcedure,
  },

  data() {
    return {
      weeks: {},
      applications: [],
      loading: false,
      loadingStats: true,
      lastProcedure: null,
      loadingWeeksPanel: false,
      showModalNewProcedure: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    userProceduresStats() {
      return this.$store.getters.getUserStats
    },
    userRoles() {
      return this.$store.getters.userPermissions
    },
    seeAsPublic() {
      return this.$store.getters.getSeeAsPublic
    },

    // Permissions
    isAdmin() {
      return this.userRoles.isAdmin
    },
    isProcedureManagerOrManager() {
      return this.userRoles.isProcedureManager || this.userRoles.isManager
    },
    isProcedureManager() {
      return this.userRoles.isProcedureManager
    },
    isManager() {
      return this.userRoles.isManager
    },
    isJury() {
      return this.userRoles.isJury
    },
    showHomeAsManage() {
      return !this.seeAsPublic && (this.isAdmin || this.isProcedureManagerOrManager || this.isJury)
    },
    stats() {
      if (this.isAdmin || this.isProcedureManagerOrManager || this.isJury) {
        return [
          {
            label: this.$t('stats.jury.asSecretariat'),
            value: this.userProceduresStats?.manager,
            valueSuffix: null,
            description: this.$t('procedures').toLowerCase(),
          },
          {
            label: this.$t('stats.jury.asPresident'),
            value: this.userProceduresStats?.president,
            valueSuffix: null,
            description: this.$t('procedures').toLowerCase(),
          },
          {
            label: this.$t('stats.jury.asVogal'),
            value: this.userProceduresStats?.member,
            valueSuffix: null,
            description: this.$t('procedures').toLowerCase(),
          },
        ]
      }

      return []
    },
  },

  watch: {
    seeAsPublic(newVal) {
      if (newVal) {
        this.getApplications()
      } else {
        this.loadLocalLastProcedure()
      }
    },
    userProceduresStats(newVal) {
      if (newVal) this.loadingStats = false
    },
  },

  mounted() {
    this.loadLocalLastProcedure()
    this.getApplications()
    if (this.userProceduresStats) this.loadingStats = false
  },

  methods: {
    goToApplication(application) {
      let view = application.status === 'submitted' ? 'dashboard' : 'metadata'
      this.$router.push('/application/' + application.code + '/' + view)
    },

    loadLocalLastProcedure() {
      const lsLast = localStorage.getItem('last-procedure-open')
      if (lsLast) this.lastProcedure = JSON.parse(lsLast)
      return false
    },

    async getApplications() {
      this.loading = true
      try {
        this.applications = await this.api.getApplications()
      } catch (error) {
        this.$buefy.toast.open({
          message: this.$t('getApplicationsError'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
      this.loading = false
    },

    async getUserWeekEvents(weekNumber, year) {
      this.loadingWeeksPanel = true

      try {
        const data = await this.api.getUserWeekEvents(weekNumber, year)
        this.weeks = Object.assign({}, this.weeks, data.weeks)
      } catch (error) {
        console.error(error)
      }

      await utils.sleep(250)
      this.loadingWeeksPanel = false
    },

    openPage(page, key) {
      if (page === 'all') {
        this.$router.push({ path: '/manage/procedures' })
      } else if (page === 'new') {
        this.$router.push({ path: '/manage/procedure/new' })
      } else if (page === 'last') {
        this.$router.push({ path: '/manage/procedure/' + key + '/dashboard' })
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "users": "Utilizadores",
    "procedures": "Procedimentos",
    "quickAccess": "Acesso rápido",
    "quickAction": "Ação rápida",
    "myApplications": "As minhas candidaturas",
    "getApplicationsError": "Ocorreu um erro ao obter as candidaturas",
    "updatedData": "Estes dados são atualizados várias vezes por dia.",
    "chooseprocedureType": "Escolha um tipo de procedimento:",
    "createProcedure": "Criar procedimento",
    "procedure": {
      "label": "Procedimento",
      "seeAll": "Ver todos os procedimentos",
      "create": "Criar novo procedimento",
      "openLast": "Abrir último procedimento"
    },
    "stats": {
      "jury": {
        "asSecretariat": "Secretariado",
        "asPresident": "Presidente",
        "asVogal": "Vogal"
      }
    },
    "meetingsGoals": {
      "jury": "Definição do júri",
      "criteria": "Definição de critérios",
      "admission": "Admissão de candidatos",
      "seriation-curriculum": "Avaliação curricular",
      "seriation-interview": "Entrevista a candidatos",
      "complaints": "Audiência(s) de interessados"
    }
  },
  "en": {
    "users": "Users",
    "procedures": "Procedures",
    "quickAccess": "Quick access",
    "quickAction": "Quick action",
    "myApplications": "My applications",
    "getApplicationsError": "An error occurred while getting the applications",
    "updatedData": "The data is updated several times per day.",
    "chooseprocedureType": "Choose a type of procedure:",
    "createProcedure": "Criar procedimento",
    "procedure": {
      "label": "Procedure",
      "seeAll": "See all procedures",
      "create": "Create new procedure",
      "openLast": "Open last procedure"
    },
    "stats": {
      "jury": {
        "asSecretariat": "Secretariat",
        "asPresident": "President",
        "asVogal": "Vogal"
      }
    },
    "meetingsGoals": {
      "jury": "Jury Definition",
      "criteria": "Definition of criteria",
      "admission": "Admission of candidates",
      "seriation-curriculum": "Curriculum evaluation",
      "seriation-interview": "Candidate interviews",
      "complaints": "Stakeholder Hearing"
    }
  }
}
</i18n>
