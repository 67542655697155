<template>
  <div
    class="rounded-xl transition-all shadow-lg hover:shadow-2xl cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0"
  >
    <div class="flex flex-col p-3 gap-3 h-full">
      <div class="flex text-sm items-center gap-2 font-semibold text-left opacity-50">
        <div>
          <fw-icon-survey class="h-5 w-5"></fw-icon-survey>
        </div>
        <div class="flex-1">
          {{ $t('application') }}
        </div>
      </div>
      <div class="flex-1 flex flex-col items-center justify-center">
        <div class="font-bold text-center w-4/5 mx-auto text-lmd">{{ application.code }}</div>
      </div>
      <div v-if="application.status" class="flex flex-col gap-3">
        <div>
          <fw-tag size="sm" :type="applicationStates[application.status].color" custom-class="px-3">
            {{ applicationStates[application.status].label[language] }}
          </fw-tag>
        </div>
        <div class="text-xs text-gray-500 px-3">
          Última atualização em {{ application.updated_date | formatDateTime }}
        </div>
        <div>
          <v-clamp v-if="application.procedure.area" autoresize :max-lines="1">
            {{ application.procedure.area }}
            {{ application.procedure.sub_area ? ' - ' + application.procedure.sub_area : '' }} -
            {{ application.procedure.faculty_title }}
            {{ application.procedure.department_title ? ' - ' + application.procedure.department_title : '' }}
          </v-clamp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APPLICATION_STATES } from '@/utils/index.js'

export default {
  name: 'CardApplication',
  props: {
    application: {
      type: Object,
    },
  },

  data() {
    return {
      applicationStates: APPLICATION_STATES['scholarship'],
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "application": "Application"
    },
    "pt": {
      "application": "Candidatura"
    }
  }
</i18n>
