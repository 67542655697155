<template>
  <fw-panel
    :title="$t('upNext')"
    :subtitle="$t('nextTenMeetings')"
    :loading="loading"
    class="my-5"
    custom-class="bg-white p-0"
  >
    <ContentLoader v-if="loading" type="row" />
    <div v-else>
      <div v-if="!next.length" class="text-gray-500 text-center text-sm p-3 bg-white rounded-xl">
        {{ $t('noUpcomingEvents') }}
      </div>
      <div v-else>
        <RecordManageMeeting v-for="meeting in next" :key="meeting.key" :item="meeting"></RecordManageMeeting>
      </div>
    </div>
  </fw-panel>
</template>

<script>
import RecordManageMeeting from '@/components/records/RecordManageMeeting'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'

export default {
  components: {
    RecordManageMeeting,
    ContentLoader,
  },

  data() {
    return {
      loading: false,
      next: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    this.getUserUpNext()
  },

  methods: {
    async getUserUpNext() {
      this.loading = true
      const meetings = await this.api.getUserUpNext({ limit: 10, fresh: 1 })
      setTimeout(() => {
        this.next = meetings
        this.loading = false
      }, 500)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "upNext": "A seguir",
    "nextTenMeetings": "Próximas 10 reuniões",
    "noUpcomingEvents": "Sem eventos."
  },
  "en": {
    "upNext": "Up Next",
    "nextTenMeetings": "Next 10 meetings",
    "noUpcomingEvents": "No upcoming events."
  }
}
</i18n>
